<template>
  <div
    class="banner-wrap d-flex align-center"
    :style="`height: ${bannerHeight}px`"
  >
    <v-container>
      <v-row
        no-gutters
        justify="center"
        align="center"
      >
        <v-col
          cols="12"
          class="white--text text-center text-h5 text-sm-h4 text-md-h3"
        >
          <span class="font-weight-bold">3분</span>안에 간병인 요청하기
        </v-col>
        <v-col
          cols="12"
          class="pt-6 text-center white--text text-caption text-sm-subtitle-2 text-md-h6 font-weight-regular"
        >
          간병인을 요청하시면 지원한 간병인의 프로필을 카톡으로 보내드립니다.
          <!-- 지원하신 <span class="font-weight-bold">간병인의 프로필</span>을 카톡으로 받아보세요 -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    isMobile: false,
    bannerHeight: 200
  }),

  beforeDestroy() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    onResize() {
      // console.log(window.innerWidth)
      const breakPoint = this.$vuetify.breakpoint.thresholds.xs
      const minHeight = 200
      let height = window.innerWidth - breakPoint
      if (height < 0) {
        height = minHeight
      } else {
        height += minHeight
        if (height >= 268) {
          height = 268
        }
      }
      this.bannerHeight = height
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-wrap {
  height:100%;
  min-height: 130px;
  max-height: 268px;
  background: url('./assets/bg.jpg') center center no-repeat;
  background-size: cover;
  // background-color: linear-gradient(90deg, #DADBE1 0%, rgba(218, 219, 225, 0.81) 44.24%, rgba(218, 219, 225, 0) 98.77%);
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dadbe1+0,d6d4d7+51,d0ced4+100 */
  background-color: #dadbe1; /* Old browsers */
  background-color: -moz-linear-gradient(top,  #dadbe1 0%, #d6d4d7 51%, #d0ced4 100%); /* FF3.6-15 */
  background-color: -webkit-linear-gradient(top,  #dadbe1 0%,#d6d4d7 51%,#d0ced4 100%); /* Chrome10-25,Safari5.1-6 */
  background-color: linear-gradient(to bottom,  #dadbe1 0%,#d6d4d7 51%,#d0ced4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dadbe1', endColorstr='#d0ced4',GradientType=0 ); /* IE6-9 */
}
</style>
